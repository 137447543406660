<template>
  <v-rating
    hover
    dense
    size="24"
    color="amber"
    empty-icon="$mdi-star"
    full-icon="$mdi-star"
    half-icon="$mdi-star-half-full"
    background-color="ap-grey"
    :value="value"
    :readonly="isReadOnly"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
